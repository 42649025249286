@use 'sass:color';

$white: #ffffff;
$black: #000000;
$red: #ff0000;
$invisible: rgba(0, 0, 0, 0);
$dark-gray: #333333;
$mid-gray: #545454;
$medium-gray-border: #c8c8c8;
$gray: #818181;
$light-gray: #f0f0f0;
$very-light-gray: #f9f9f9;
$gray--opacity--20: rgba($gray, 0.2);
$gray--opacity--40: rgba($gray, 0.4);
$gray--opacity--60: rgba($gray, 0.6);
$gray--opacity--80: rgba($gray, 0.8);
$light-gray--opacity--35: rgba($light-gray, 0.35);
$light-gray--opacity--40: rgba($light-gray, 0.4);
$light-gray--opacity--70: rgba($light-gray, 0.7);
$brand-action: #54df63;
$brand-action-dark: color.scale($brand-action, $lightness: -15%);
$brand-action-light: color.scale($brand-action, $lightness: 50%);
$brand-accent: #2693e9;
$brand-accent--opacity--10: rgba($brand-accent, 0.1);
$brand-accent-light: color.scale($brand-accent, $lightness: 50%);
$brand-accent-dark: color.scale($brand-accent, $lightness: -15%);
$brand-navy: #0b2951;
$error: #c6192e;
$estimator-error: #ff6a6a;
$err: $error;
$warning: #f4c94f;
$bright-highlight: #3fb94d;
$mustard: #ffc700;
$mustard-light: #fff7d9;
$orange: #ba9100;
// FONTS
$xs-font: 10px;
$sm-font: 13px;
$rg-font: 14px;
$rg-md-font: 16px;
$md-font: 20px;
$lg-font: 24px;
$xl-font: 64px;
$font-family-brand: 'Open Sans';
$font-family-neutral: 'Open Sans' !important;
// BREAKPOINTS
$screen-xs-max: 320px;
$screen-xs-min: 321px;
$screen-sm-min: 768px;
$screen-md-min: 922px;
$screen-lg-min: 1200px;
$screen-sm-max: 767px;
$screen-md-max: 921px;
$screen-lg-max: 1199px;
$header-one-color: $dark-gray;
$header-one-font: $font-family-brand;
$header-one-size: 28px;
$header-two-color: $dark-gray;
$header-two-font: $font-family-brand;
$header-two-size: 20px;
$separate-line-color: #b6babf;
$subheader-color: $mid-gray;
$subheader-font: $font-family-brand;
$subheader-size: 16px;
$link-color: $brand-accent;
$link-font: $font-family-neutral;
$body-color: $gray;
$body-font: $font-family-neutral;
$body-size: 13px;
$footnote-color: $gray;
$footnote-font: $font-family-neutral;
$footnote-size: 14px;
// PADDING
$padding-xxs: 5px;
$padding-xs: 10px;
$padding-sm: 15px;
$padding-md: 20px;
$padding-lg: 30px;
$padding-xl: 40px;
$padding-xxl: 50px;
@mixin body {
	color: $body-color;
	font-family: $body-font;
	font-size: $body-size;
}

@mixin h1 {
	color: $header-one-color;
	font-family: $header-one-font;
	font-size: $header-one-size;
	@media screen and (max-width: $screen-sm-max) {
		font-size: $header-one-size - 4;
	}
}

@mixin h2 {
	color: $header-two-color;
	font-family: $header-two-font;
	font-size: $header-two-size;
	line-height: 1.3em;
	@media screen and (max-width: $screen-sm-max) {
		font-size: $header-two-size - 2;
	}
}

@mixin h5 {
	color: $subheader-color;
	font-family: $subheader-font;
	font-size: $subheader-size;
}

@mixin link {
	color: $link-color;
	font-family: $link-font;
}

@mixin footnote {
	color: $footnote-color;
	font-family: $footnote-font;
	font-size: $footnote-size;
}
