@use 'sass:color';

@import './vars.scss';

$themes: (
	cadillac: $cadillac,
	carlotz: $carlotz,
	chevy: $chevy,
	consumer: $consumer,
	chrysler: $chrysler,
	ford: $ford,
	genesis: $genesis,
	gmc: $gmc,
	hyundai: $hyundai,
	jaguar: $jaguar,
	kia: $kia,
	landrover: $landrover,
	lexus: $lexus,
	lincoln: $lincoln,
	lithia: $lithia,
	mazda: $mazda,
	nissan: $nissan,
	rocketauto: $rocketauto,
	setoyotagray: $setoyotagray,
	volkswagen: $volkswagen,
	volvo: $volvo,
	vroom: $vroom,
);

@mixin themify($property, $key, $default: '', $modifier: 'identity') {
	// Iterate over the themes
	@each $theme, $var in $themes {
		// Create a selector (e.g. `.media.theme-unicorn, .theme-unicorn .media`)
		@if $theme != 'consumer' {
			@if map-has-key($var, $key) {
				:global(.theme-#{$theme}) & {
					// Output the declaration
					#{$property}: call(get-function($modifier), if(map-has-key($var, $key), map-get($var, $key), $default));
				}
			}
		} @else {
			#{$property}: call(get-function($modifier), if(map-has-key($var, $key), map-get($var, $key), $default));
		}
	}
}

@function identity($value) {
	@return $value;
}
