@use 'sass:color';

$white: #ffffff;
$black: #000000;
$invisible: rgba(0, 0, 0, 0);
$darkGray: #333333;
$midGray: #545454;
$gray: #818181;
$lightGray: #f0f0f0;
$acura: (brand-accent: #ed2024, brand-action: #ed2024, brand-contrast: #ffffff, font-family-brand: Open Sans, font-family-neutral: 'Open Sans' !important, brand-action-dark: color.scale(#ed2024, $lightness: -15%), brand-accent-light: color.scale(#ed2024, $lightness: 50%), brand-accent-faded: rgba(#ed2024, 0.4), brand-accent-extra-faded: rgba(#ed2024, 0.1), nav-brand-color: #ed2024, header-one-color: $darkGray, header-one-font: Open Sans, header-one-size: 28px, header-two-color: $darkGray, header-two-font: Open Sans, header-two-size: 20px, subheader-color: $midGray, subheader-font: Open Sans, subheader-size: 16px, link-color: #ed2024, link-font: 'Open Sans' !important, body-color: $gray, body-font: 'Open Sans' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'Open Sans' !important, footnote-size: 14px);
$audi: (brand-accent: #54df63, brand-action: #54df63, brand-contrast: #ffffff, font-family-brand: Open Sans, font-family-neutral: 'Open Sans' !important, brand-action-dark: color.scale(#54df63, $lightness: -15%), brand-accent-light: color.scale(#54df63, $lightness: 50%), brand-accent-faded: rgba(#54df63, 0.4), brand-accent-extra-faded: rgba(#54df63, 0.1), nav-brand-color: #54df63, header-one-color: $darkGray, header-one-font: Open Sans, header-one-size: 28px, header-two-color: $darkGray, header-two-font: Open Sans, header-two-size: 20px, subheader-color: $midGray, subheader-font: Open Sans, subheader-size: 16px, link-color: #54df63, link-font: 'Open Sans' !important, body-color: $gray, body-font: 'Open Sans' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'Open Sans' !important, footnote-size: 14px);
$cadillac: (brand-accent: #8a1729, brand-action: #8a1729, brand-contrast: #ffffff, font-family-brand: Open Sans, font-family-neutral: 'Open Sans' !important, brand-action-dark: color.scale(#8a1729, $lightness: -15%), brand-accent-light: color.scale(#8a1729, $lightness: 50%), brand-accent-faded: rgba(#8a1729, 0.4), brand-accent-extra-faded: rgba(#8a1729, 0.1), nav-brand-color: #8a1729, header-one-color: $darkGray, header-one-font: Open Sans, header-one-size: 28px, header-two-color: $darkGray, header-two-font: Open Sans, header-two-size: 20px, subheader-color: $midGray, subheader-font: Open Sans, subheader-size: 16px, link-color: #8a1729, link-font: 'Open Sans' !important, body-color: $gray, body-font: 'Open Sans' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'Open Sans' !important, footnote-size: 14px);
$carlotz: (brand-accent: #FFA300, brand-action: #FFA300, brand-contrast: #373A36, font-family-brand: Poppins Regular, font-family-neutral: 'Poppins Regular' !important, brand-action-light: color.scale(#FFA300, $lightness: 70%), brand-action-dark: color.scale(#FFA300, $lightness: -15%), brand-accent-light: color.scale(#FFA300, $lightness: 75%), brand-accent-dark: color.scale(#FFA300, $lightness: -15%), brand-accent-faded: rgba(#FFA300, 0.4), brand-accent-extra-faded: rgba(#FFA300, 0.1), nav-brand-color: #FFA300, header-one-color: $darkGray, header-one-font: Poppins Regular, header-one-size: 28px, header-two-color: $darkGray, header-two-font: Poppins Regular, header-two-size: 20px, subheader-color: $midGray, subheader-font: Poppins Regular, subheader-size: 16px, link-color: #FFA300, link-font: 'Poppins Regular' !important, body-color: $gray, body-font: 'Poppins Regular' !important, body-size: 12px, footnote-color: $gray, footnote-font: 'Poppins Regular' !important, footnote-size: 14px);
$chevy: (brand-accent: #f2bf24, brand-action: #f2bf24, brand-contrast: #ffffff, font-family-brand: Open Sans, font-family-neutral: 'Open Sans' !important, brand-action-dark: color.scale(#f2bf24, $lightness: -15%), brand-accent-light: color.scale(#f2bf24, $lightness: 50%), brand-accent-faded: rgba(#f2bf24, 0.4), brand-accent-extra-faded: rgba(#f2bf24, 0.1), nav-brand-color: #f2bf24, header-one-color: $darkGray, header-one-font: Open Sans, header-one-size: 28px, header-two-color: $darkGray, header-two-font: Open Sans, header-two-size: 20px, subheader-color: $midGray, subheader-font: Open Sans, subheader-size: 16px, link-color: #f2bf24, link-font: 'Open Sans' !important, body-color: $gray, body-font: 'Open Sans' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'Open Sans' !important, footnote-size: 14px);
$chrysler: (brand-accent: #3A57A0, brand-action: #3A57A0, brand-contrast: #ffffff, font-family-brand: Helvetica Neue LT, font-family-neutral: 'Open Sans' !important, brand-action-light: color.scale(#3A57A0, $lightness: 70%), brand-action-dark: color.scale(#3A57A0, $lightness: -15%), brand-accent-light: color.scale(#3A57A0, $lightness: 75%), brand-accent-dark: color.scale(#3A57A0, $lightness: -15%), brand-accent-faded: rgba(#3A57A0, 0.4), brand-accent-extra-faded: rgba(#3A57A0, 0.1), nav-brand-color: #3A57A0, header-one-color: $darkGray, header-one-font: Helvetica Neue LT, header-one-size: 28px, header-two-color: $darkGray, header-two-font: Helvetica Neue LT, header-two-size: 20px, subheader-color: $midGray, subheader-font: Helvetica Neue LT, subheader-size: 16px, link-color: #3A57A0, link-font: 'Open Sans' !important, body-color: $gray, body-font: 'Open Sans' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'Open Sans' !important, footnote-size: 14px);
$consumer: (brand-accent: #2693e9, brand-action: #54df63, brand-contrast: #ffffff, font-family-brand: Open Sans, font-family-neutral: 'Open Sans' !important, brand-action-dark: color.scale(#54df63, $lightness: -15%), brand-accent-light: color.scale(#2693e9, $lightness: 50%), brand-accent-faded: rgba(#2693e9, 0.4), brand-accent-extra-faded: rgba(#2693e9, 0.1), nav-brand-color: #2693e9, error: #c6192e, err: #c6192e, header-one-color: $darkGray, header-one-font: Open Sans, header-one-size: 28px, header-two-color: $darkGray, header-two-font: Open Sans, header-two-size: 20px, subheader-color: $midGray, subheader-font: Open Sans, subheader-size: 16px, link-color: #2693e9, link-font: 'Open Sans' !important, body-color: $gray, body-font: 'Open Sans' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'Open Sans' !important, footnote-size: 14px);
$ford: (brand-accent: #066fef, brand-action: #066fef, brand-contrast: #ffffff, font-family-brand: Ford F-1 Regular, font-family-neutral: 'Ford F-1 Regular' !important, brand-action-dark: color.scale(#066fef, $lightness: -15%), brand-accent-light: color.scale(#066fef, $lightness: 50%), brand-accent-faded: rgba(#066fef, 0.4), brand-accent-extra-faded: rgba(#066fef, 0.1), nav-brand-color: #066fef, header-one-color: $darkGray, header-one-font: Ford F-1 Regular, header-one-size: 28px, header-two-color: $darkGray, header-two-font: Ford F-1 Regular, header-two-size: 20px, subheader-color: $midGray, subheader-font: Ford F-1 Regular, subheader-size: 16px, link-color: #066fef, link-font: 'Ford F-1 Regular' !important, body-color: $gray, body-font: 'Ford F-1 Regular' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'Ford F-1 Regular' !important, footnote-size: 14px);
$genesis: (brand-accent: #1A1A1A, brand-action: #1A1A1A, brand-contrast: #FFFFFF, font-family-brand: genesis, font-family-neutral: 'genesis' !important, brand-action-dark: color.scale(#1A1A1A, $lightness: -15%), brand-accent-light: color.scale(#1A1A1A, $lightness: 50%), brand-accent-faded: rgba(#1A1A1A, 0.4), brand-accent-extra-faded: rgba(#1A1A1A, 0.1), nav-brand-color: #1A1A1A, header-one-color: $darkGray, header-one-font: genesis, header-one-size: 28px, header-two-color: $darkGray, header-two-font: genesis, header-two-size: 20px, subheader-color: $midGray, subheader-font: genesis, subheader-size: 16px, link-color: #1A1A1A, link-font: 'genesis' !important, body-color: $gray, body-font: 'genesis' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'genesis' !important, footnote-size: 14px);
$gmc: (brand-accent: #CC0000, brand-action: #CC0000, brand-contrast: #ffffff, font-family-brand: Open Sans, font-family-neutral: 'Open Sans' !important, brand-action-dark: color.scale(#CC0000, $lightness: -15%), brand-accent-light: color.scale(#CC0000, $lightness: 50%), brand-accent-faded: rgba(#CC0000, 0.4), brand-accent-extra-faded: rgba(#CC0000, 0.1), nav-brand-color: #CC0000, header-one-color: $darkGray, header-one-font: Open Sans, header-one-size: 28px, header-two-color: $darkGray, header-two-font: Open Sans, header-two-size: 20px, subheader-color: $midGray, subheader-font: Open Sans, subheader-size: 16px, link-color: #CC0000, link-font: 'Open Sans' !important, body-color: $gray, body-font: 'Open Sans' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'Open Sans' !important, footnote-size: 14px);
$honda: (brand-accent: #0084c6, brand-action: #0084c6, brand-contrast: #ffffff, font-family-brand: Open Sans, font-family-neutral: 'Open Sans' !important, brand-action-dark: color.scale(#0084c6, $lightness: -15%), brand-accent-light: color.scale(#0084c6, $lightness: 50%), brand-accent-faded: rgba(#0084c6, 0.4), brand-accent-extra-faded: rgba(#0084c6, 0.1), nav-brand-color: #0084c6, header-one-color: $darkGray, header-one-font: Open Sans, header-one-size: 28px, header-two-color: $darkGray, header-two-font: Open Sans, header-two-size: 20px, subheader-color: $midGray, subheader-font: Open Sans, subheader-size: 16px, link-color: #0084c6, link-font: 'Open Sans' !important, body-color: $gray, body-font: 'Open Sans' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'Open Sans' !important, footnote-size: 14px);
$hyundai: (brand-accent: #336699, brand-action: #336699, brand-contrast: #ffffff, font-family-brand: Open Sans, font-family-neutral: 'Open Sans' !important, brand-action-dark: color.scale(#336699, $lightness: -15%), brand-accent-light: color.scale(#336699, $lightness: 50%), brand-accent-faded: rgba(#336699, 0.4), brand-accent-extra-faded: rgba(#336699, 0.1), nav-brand-color: #336699, header-one-color: $darkGray, header-one-font: Open Sans, header-one-size: 28px, header-two-color: $darkGray, header-two-font: Open Sans, header-two-size: 20px, subheader-color: $midGray, subheader-font: Open Sans, subheader-size: 16px, link-color: #336699, link-font: 'Open Sans' !important, body-color: $gray, body-font: 'Open Sans' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'Open Sans' !important, footnote-size: 14px);
$jaguar: (brand-accent: #0c121c, brand-action: #0c121c, brand-contrast: #ffffff, font-family-brand: Open Sans, font-family-neutral: 'Open Sans' !important, brand-action-dark: color.scale(#0c121c, $lightness: -15%), brand-accent-light: color.scale(#0c121c, $lightness: 50%), brand-accent-faded: rgba(#0c121c, 0.4), brand-accent-extra-faded: rgba(#0c121c, 0.1), nav-brand-color: #0c121c, header-one-color: $darkGray, header-one-font: Open Sans, header-one-size: 28px, header-two-color: $darkGray, header-two-font: Open Sans, header-two-size: 20px, subheader-color: $midGray, subheader-font: Open Sans, subheader-size: 16px, link-color: #0c121c, link-font: 'Open Sans' !important, body-color: $gray, body-font: 'Open Sans' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'Open Sans' !important, footnote-size: 14px);
$kia: (brand-accent: #9EA1A2, brand-action: #9EA1A2, brand-contrast: #05151F, font-family-brand: Open Sans, font-family-neutral: 'Open Sans' !important, brand-action-dark: color.scale(#9EA1A2, $lightness: -15%), brand-accent-light: color.scale(#9EA1A2, $lightness: 50%), brand-accent-faded: rgba(#9EA1A2, 0.4), brand-accent-extra-faded: rgba(#9EA1A2, 0.1), nav-brand-color: #9EA1A2, header-one-color: $darkGray, header-one-font: Open Sans, header-one-size: 28px, header-two-color: $darkGray, header-two-font: Open Sans, header-two-size: 20px, subheader-color: $midGray, subheader-font: Open Sans, subheader-size: 16px, link-color: #9EA1A2, link-font: 'Open Sans' !important, body-color: $gray, body-font: 'Open Sans' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'Open Sans' !important, footnote-size: 14px);
$landrover: (brand-accent: #005a2b, brand-action: #005a2b, brand-contrast: #ffffff, font-family-brand: Open Sans, font-family-neutral: 'Open Sans' !important, brand-action-dark: color.scale(#005a2b, $lightness: -15%), brand-accent-light: color.scale(#005a2b, $lightness: 50%), brand-accent-faded: rgba(#005a2b, 0.4), brand-accent-extra-faded: rgba(#005a2b, 0.1), nav-brand-color: #005a2b, header-one-color: $darkGray, header-one-font: Open Sans, header-one-size: 28px, header-two-color: $darkGray, header-two-font: Open Sans, header-two-size: 20px, subheader-color: $midGray, subheader-font: Open Sans, subheader-size: 16px, link-color: #005a2b, link-font: 'Open Sans' !important, body-color: $gray, body-font: 'Open Sans' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'Open Sans' !important, footnote-size: 14px);
$lexus: (brand-accent: #5c7f92, brand-action: #5c7f92, brand-contrast: #ffffff, font-family-brand: nobel, font-family-neutral: 'nobel' !important, brand-action-dark: color.scale(#5c7f92, $lightness: -15%), brand-accent-light: color.scale(#5c7f92, $lightness: 50%), brand-accent-faded: rgba(#5c7f92, 0.4), brand-accent-extra-faded: rgba(#5c7f92, 0.1), nav-brand-color: #5c7f92, header-one-color: $darkGray, header-one-font: nobel, header-one-size: 28px, header-two-color: $darkGray, header-two-font: nobel, header-two-size: 20px, subheader-color: $midGray, subheader-font: nobel, subheader-size: 16px, link-color: #5c7f92, link-font: 'nobel' !important, body-color: $gray, body-font: 'nobel' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'nobel' !important, footnote-size: 14px);
$lincoln: (brand-accent: #324047, brand-action: #324047, brand-contrast: #ECEDEE, font-family-brand: Lincoln-MillerB, font-family-neutral: 'Lincoln-ProximaN' !important, brand-action-light: color.scale(#324047, $lightness: 70%), brand-action-dark: color.scale(#324047, $lightness: -15%), brand-accent-light: color.scale(#324047, $lightness: 75%), brand-accent-dark: color.scale(#324047, $lightness: -15%), brand-accent-faded: rgba(#324047, 0.4), brand-accent-extra-faded: rgba(#324047, 0.1), nav-brand-color: #324047, header-one-color: $darkGray, header-one-font: Lincoln-MillerB, header-one-size: 28px, header-two-color: $darkGray, header-two-font: Lincoln-MillerB, header-two-size: 20px, subheader-color: $midGray, subheader-font: Lincoln-MillerB, subheader-size: 16px, link-color: #324047, link-font: 'Lincoln-ProximaN' !important, body-color: $gray, body-font: 'Lincoln-ProximaN' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'Lincoln-ProximaN' !important, footnote-size: 14px);
$lithia: (brand-accent: #027DB6, brand-action: #027DB6, brand-contrast: #ffffff, font-family-brand: Work-Sans, font-family-neutral: 'Work-Sans' !important, brand-action-dark: color.scale(#027DB6, $lightness: -15%), brand-accent-light: color.scale(#027DB6, $lightness: 50%), brand-accent-faded: rgba(#027DB6, 0.4), brand-accent-extra-faded: rgba(#027DB6, 0.1), nav-brand-color: #027DB6, header-one-color: #124370, header-one-font: Work-Sans, header-one-size: 28px, header-two-color: #124370, header-two-font: Work-Sans, header-two-size: 20px, subheader-color: #124370, subheader-font: Work-Sans, subheader-size: 16px, link-color: #027DB6, link-font: 'Work-Sans' !important, body-color: #124370, body-font: 'Work-Sans' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'Work-Sans' !important, footnote-size: 14px);
$mazda: (brand-accent: #999999, brand-action: #999999, brand-contrast: #ffffff, font-family-brand: Mazda Regular, font-family-neutral: 'Mazda Regular' !important, brand-action-dark: color.scale(#999999, $lightness: -15%), brand-accent-light: color.scale(#999999, $lightness: 50%), brand-accent-faded: rgba(#999999, 0.4), brand-accent-extra-faded: rgba(#999999, 0.1), nav-brand-color: #999999, header-one-color: $darkGray, header-one-font: Mazda Regular, header-one-size: 28px, header-two-color: $darkGray, header-two-font: Mazda Regular, header-two-size: 20px, subheader-color: $midGray, subheader-font: Mazda Regular, subheader-size: 16px, link-color: #999999, link-font: 'Mazda Regular' !important, body-color: $gray, body-font: 'Mazda Regular' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'Mazda Regular' !important, footnote-size: 14px);
$mitsubushi: (brand-accent: #54df63, brand-action: #54df63, brand-contrast: #ffffff, font-family-brand: Open Sans, font-family-neutral: 'Open Sans' !important, brand-action-dark: color.scale(#54df63, $lightness: -15%), brand-accent-light: color.scale(#54df63, $lightness: 50%), brand-accent-faded: rgba(#54df63, 0.4), brand-accent-extra-faded: rgba(#54df63, 0.1), nav-brand-color: #54df63, header-one-color: $darkGray, header-one-font: Open Sans, header-one-size: 28px, header-two-color: $darkGray, header-two-font: Open Sans, header-two-size: 20px, subheader-color: $midGray, subheader-font: Open Sans, subheader-size: 16px, link-color: #54df63, link-font: 'Open Sans' !important, body-color: $gray, body-font: 'Open Sans' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'Open Sans' !important, footnote-size: 14px);
$nissan: (brand-accent: #c92034, brand-action: #c92034, brand-contrast: #ffffff, font-family-brand: Helvetica Neue LT, font-family-neutral: 'Open Sans' !important, brand-action-light: color.scale(#c92034, $lightness: 70%), brand-action-dark: color.scale(#c92034, $lightness: -15%), brand-accent-light: color.scale(#c92034, $lightness: 75%), brand-accent-dark: color.scale(#c92034, $lightness: -15%), brand-accent-faded: rgba(#c92034, 0.4), brand-accent-extra-faded: rgba(#c92034, 0.1), nav-brand-color: #c92034, header-one-color: $darkGray, header-one-font: Helvetica Neue LT, header-one-size: 28px, header-two-color: $darkGray, header-two-font: Helvetica Neue LT, header-two-size: 20px, subheader-color: $midGray, subheader-font: Helvetica Neue LT, subheader-size: 16px, link-color: #c92034, link-font: 'Open Sans' !important, body-color: $gray, body-font: 'Open Sans' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'Open Sans' !important, footnote-size: 14px);
$rocketauto: (brand-accent: #c8102e, brand-action: #c8102e, brand-contrast: #ffffff, font-family-brand: Rocket Sans, font-family-neutral: 'Rocket Sans' !important, brand-action-dark: color.scale(#c8102e, $lightness: -15%), brand-accent-light: color.scale(#c8102e, $lightness: 50%), brand-accent-faded: rgba(#c8102e, 0.4), brand-accent-extra-faded: rgba(#c8102e, 0.1), nav-brand-color: #c8102e, header-one-color: $darkGray, header-one-font: Rocket Sans, header-one-size: 28px, header-two-color: $darkGray, header-two-font: Rocket Sans, header-two-size: 20px, subheader-color: $midGray, subheader-font: Rocket Sans, subheader-size: 16px, link-color: #c8102e, link-font: 'Rocket Sans' !important, body-color: $gray, body-font: 'Rocket Sans' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'Rocket Sans' !important, footnote-size: 14px);
$setoyotagray: (brand-accent: #CC0000, brand-action: #CC0000, brand-contrast: #ffffff, font-family-brand: Helvetica Neue LT, font-family-neutral: 'Open Sans' !important, brand-action-light: color.scale(#CC0000, $lightness: 70%), brand-action-dark: color.scale(#CC0000, $lightness: -15%), brand-accent-light: color.scale(#CC0000, $lightness: 75%), brand-accent-dark: color.scale(#CC0000, $lightness: -15%), brand-accent-faded: rgba(#CC0000, 0.4), brand-accent-extra-faded: rgba(#CC0000, 0.1), nav-brand-color: #CC0000, error: #f56D74, err: #f56D74, header-one-color: $darkGray, header-one-font: Helvetica Neue LT, header-one-size: 28px, header-two-color: $darkGray, header-two-font: Helvetica Neue LT, header-two-size: 20px, subheader-color: $midGray, subheader-font: Helvetica Neue LT, subheader-size: 16px, link-color: #CC0000, link-font: 'Open Sans' !important, body-color: $gray, body-font: 'Open Sans' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'Open Sans' !important, footnote-size: 14px);
$subaru: (brand-accent: #004890, brand-action: #004890, brand-contrast: #ffffff, font-family-brand: Helvetica, font-family-neutral: 'Helvetica' !important, brand-action-dark: color.scale(#004890, $lightness: -15%), brand-accent-light: color.scale(#004890, $lightness: 50%), brand-accent-faded: rgba(#004890, 0.4), brand-accent-extra-faded: rgba(#004890, 0.1), nav-brand-color: #004890, header-one-color: $darkGray, header-one-font: Helvetica, header-one-size: 28px, header-two-color: $darkGray, header-two-font: Helvetica, header-two-size: 20px, subheader-color: $midGray, subheader-font: Helvetica, subheader-size: 16px, link-color: #004890, link-font: 'Helvetica' !important, body-color: $gray, body-font: 'Helvetica' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'Helvetica' !important, footnote-size: 14px);
$volkswagen: (brand-accent: #001e50, brand-action: #001e50, brand-contrast: #ffffff, brand-button-border-radius: 9999px, brand-button-transition: all 0.2s ease-in-out, brand-button-ps-background-color: #0040c5, brand-button-ps-color: #ffffff !important, brand-button-color: #ffffff, font-family-brand: vw-head, font-family-neutral: 'vw-head' !important, brand-action-dark: color.scale(#001e50, $lightness: -15%), brand-accent-light: color.scale(#001e50, $lightness: 50%), brand-accent-faded: rgba(#001e50, 0.4), brand-accent-extra-faded: rgba(#001e50, 0.1), nav-brand-color: #001e50, header-one-color: $darkGray, header-one-font: vw-head, header-one-size: 28px, header-two-color: $darkGray, header-two-font: vw-head, header-two-size: 20px, subheader-color: $midGray, subheader-font: vw-head, subheader-size: 16px, link-color: #001e50, link-font: 'vw-head' !important, body-color: $gray, body-font: 'vw-head' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'vw-head' !important, footnote-size: 14px);
$volvo: (brand-accent: #2b7bcd, brand-action: #2b7bcd, brand-contrast: #ffffff, font-family-brand: Open Sans, font-family-neutral: 'Open Sans' !important, brand-action-dark: color.scale(#2b7bcd, $lightness: -15%), brand-accent-light: color.scale(#2b7bcd, $lightness: 50%), brand-accent-faded: rgba(#2b7bcd, 0.4), brand-accent-extra-faded: rgba(#2b7bcd, 0.1), nav-brand-color: #2b7bcd, header-one-color: $darkGray, header-one-font: Open Sans, header-one-size: 28px, header-two-color: $darkGray, header-two-font: Open Sans, header-two-size: 20px, subheader-color: $midGray, subheader-font: Open Sans, subheader-size: 16px, link-color: #2b7bcd, link-font: 'Open Sans' !important, body-color: $gray, body-font: 'Open Sans' !important, body-size: 13px, footnote-color: $gray, footnote-font: 'Open Sans' !important, footnote-size: 14px);
$vroom: (brand-accent: #E7131A, brand-action: #E7131A, brand-contrast: #ffffff, font-family-brand: Vroom-Sans, font-family-neutral: Calibre-Regular !important, brand-action-dark: color.scale(#E7131A, $lightness: -15%), brand-accent-light: color.scale(#E7131A, $lightness: 50%), brand-accent-faded: rgba(#E7131A, 0.4), brand-accent-extra-faded: rgba(#E7131A, 0.1), nav-brand-color: #E7131A, header-one-color: #041022, header-one-font: Calibre-Semibold !important, header-one-size: 28px, header-two-color: #041022, header-two-font: Vroom-Sans, header-two-size: 20px, subheader-color: #041022, subheader-font: Calibre-Semibold !important, subheader-size: 16px, link-color: #E7131A, link-font: Calibre-Regular !important, body-color: #041022, body-font: Calibre-Regular !important, body-size: 13px, footnote-color: $gray, footnote-font: Calibre-Regular !important, footnote-size: 14px);
@import '../../themes.scss';

.afi-v3-placeholder {
	pointer-events: none;
}

.afi-v3-apply {
	width: calc(100% - 1em) !important;
}

.tooltip-container {
	:global {
		.info-button {
			margin-left: 5px;
		}

		.media {
			left: -10px;
			width: 290px;

			.media-body {
				.media-title {
					color: $dark-gray;
					padding: 0;
				}
				.media-content {
					font-weight: normal;
				}
			}

			.arrow-selector {
				left: 15px;
			}
		}
	}
}
